import React from 'react'
import { Navigate, useNavigate } from 'react-router-dom'

function Header() {
  const navigate = useNavigate()
  return (
    <div className="main_menu home_menu " style={{ backgroundColor: 'white' }}>
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            <nav className="navbar navbar-expand-lg navbar-light">
              <a className="navbar-brand" href="/">
                <img src="img/v5_tis.jpg" alt="logo" />
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="ti-menu" />
              </button>
              <div
                className="collapse navbar-collapse main-menu-item justify-content-end"
                id="navbarSupportedContent"
                style={{ cursor: 'pointer' }}
              >
                <ul className="navbar-nav align-items-center">
                  <li className="nav-item">
                    <a className="nav-link" onClick={() => navigate('/')}>
                      Home
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={() => navigate('/profile')}>
                      Profile
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={() => navigate('/project')}>
                      Projects
                    </a>{' '}
                    {/* Corrected href */}
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={() => navigate('/services')}>
                      Services
                    </a>{' '}
                    {/* Corrected href */}
                  </li>
                  <li className="nav-item">
                    <a className="nav-link">Gallery</a>
                  </li>
                  {/* Uncomment and update this block if needed */}
                  {/* <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="blog.html"
                      id="navbarDropdown"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      Gallery
                    </a>
                    <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                      <a className="dropdown-item" href="project.html">Project</a>
                      <a className="dropdown-item" href="project_details.html">Project Details</a>
                      <a className="dropdown-item" href="single-blog.html">Single Blog</a>
                      <a className="dropdown-item" href="elements.html">Elements</a>
                    </div>
                  </li> */}
                  <li className="nav-item">
                    <a className="nav-link" onClick={() => navigate('/contact')}>
                      Contact
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={() => navigate('/login')}>
                      Login
                    </a>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
