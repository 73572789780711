import React from 'react'
import Footer from './Footer'
import Header from 'src/NavigationBar/Header'
import { useNavigate } from 'react-router-dom'
// import className  from '../../public/css'

function Mainpage() {
  const navigate = useNavigate()
  return (
    <>
      <Header />
      <section className="banner_part">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 col-xl-6">
              <div className="banner_text">
                <div className="banner_text_iner">
                  <h1 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                    Committed to <span>Superior</span> Quality and Results
                  </h1>
                  <p>
                    The success of the young entrepreneur will be the key to india&apos;s
                    transformation in the new millennium.
                  </p>
                  <a onClick={() => navigate('/project')} className="btn_1">
                    View project
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about_part section_padding">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="about_part_img">
                <img src="img/tis13.jpeg" alt="image" />
              </div>
            </div>
            <div className="col-md-6 col-lg-5">
              <div className="about_part_text">
                <h2 style={{ textAlign: 'justify', color: '#001b5e' }}>
                  V5 Infra Mission Dreams With Us
                </h2>
                <p style={{ textAlign: 'justify', color: '#001b5e' }}>
                  <h6 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                    Mission Statement
                  </h6>
                  At V5 Infra and Allied Services, our mission is to excel as a premier provider of
                  Total Implementation Services (TIS) to banks, infrastructure services for retail
                  outlets, and interior solutions for business units. We are dedicated to delivering
                  exceptional quality, reliability, and efficiency in every project we undertake.
                  Through innovative solutions and a commitment to client satisfaction, we aim to
                  set new standards of excellence in the industries we serve.
                  <hr />
                  <h6 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                    Vision Statement
                  </h6>
                  Our vision at V5 Infra and Allied Services is to be the trusted partner of choice
                  in TIS for banks, infrastructure services for retail outlets, and interior
                  solutions for business units. We strive to be renowned for our expertise,
                  integrity, and customer-centric approach, setting benchmarks for service
                  excellence and innovation. By leveraging advanced technologies and sustainable
                  practices, we aspire to contribute significantly to the growth and success of our
                  clients, while driving positive change in the industry.
                </p>
                <ul>
                  <li>
                    <span className="flaticon-drop" />
                    <h3 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                      Certified Company
                    </h3>
                    <p>
                      Be man air male shall under create light together grass fly dat also also his
                      brought itself air abundantly
                    </p>
                  </li>
                  <li>
                    <span className="flaticon-ui" />
                    <h3 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                      Experience Employee
                    </h3>
                    <p>
                      Be man air male shall under create light together grass fly do also also his
                      brought itself air abundantly
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_service padding_top">
        <div className="container">
          <div className="row">
            <div className="col-xl-5">
              <div className="section_tittle">
                <h2 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>our services</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-xl-4">
              <div className="single_feature">
                <div className="single_service">
                  <span className="flaticon-ui" />
                  <h4 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                    Better Future
                  </h4>
                  <p>
                    You can&apos;t go back and change the beginning, but you can start where you are
                    and change the ending.
                  </p>
                  <a onClick={() => navigate('/services')} className="btn_3">
                    read more
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="single_feature">
                <div className="single_service">
                  <span className="flaticon-ui" />
                  <h4 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                    Qualified Trainers
                  </h4>
                  <p>
                    We don&apos;t need to be perfect we just need to get started. Shift the focus
                    from what your body looks like to what it can do.
                  </p>
                  <a onClick={() => navigate('/services')} className="btn_3">
                    read more
                  </a>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-xl-4">
              <div className="single_feature">
                <div className="single_service single_service_2">
                  <span className="flaticon-ui" />
                  <h4 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                    Job Oppurtunity
                  </h4>
                  <p>
                    If opportunity doesn&apos;t knock, build a door. Starting a new job can be
                    nerve-racking, but it&apos;s also exciting
                  </p>
                  <a onClick={() => navigate('/services')} className="btn_3">
                    read more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about_part experiance_part section_padding">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-6 col-lg-6">
              <div className="about_part_text">
                <h2 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                  We Are Experience in Construction
                </h2>
                <p style={{ textAlign: 'justify' }}>
                  <h6 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                    Our Services
                  </h6>
                  At V5 Infra and Allied Services, we specialize in
                  <hr />
                  <h6 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                    Total Implementation Services (TIS) for Banks
                  </h6>{' '}
                  We offer end-to-end solutions tailored to meet the unique needs of banking
                  institutions, ensuring seamless implementation of new branches, ATMs, and
                  specialized banking facilities.
                  <hr />
                  <h6 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                    Infrastructure Services for Retail Outlets
                  </h6>{' '}
                  Our expertise extends to infrastructure setup and maintenance for retail outlets,
                  including design, construction, and ongoing support to enhance operational
                  efficiency.
                  <hr />
                  <h6 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                    Interior Solutions for Business Units
                  </h6>{' '}
                  We provide innovative interior design and fit-out services that transform
                  commercial spaces into functional and inspiring environments, catering to diverse
                  business requirements.
                  <hr />
                  <h6 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                    Nationwide Reach
                  </h6>
                  Our services are available PAN India, allowing us to serve clients from diverse
                  industries and geographic locations with consistency and efficiency. Whether
                  you&apos;re in Mumbai, Delhi, Bangalore, or any other part of the country, V5
                  Infra and Allied Services is your dedicated partner for achieving your
                  infrastructure and interior goals.
                  <hr />
                  <h6 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                    Our Leadership
                  </h6>
                  Under the visionary leadership of Mr. Dinesh Choudhary, V5 Infra and Allied
                  Services has grown to become a leader in the industry, known for our commitment to
                  quality, innovation, and customer satisfaction. Mr. Choudhary&apos;s strategic
                  insights and hands-on approach ensure that every project undertaken by our team
                  meets the highest standards of excellence.
                </p>
                <div className="about_text_iner">
                  <div className="about_text_counter">
                    <h2 style={{ color: '#FF5E13', fontFamily: 'Roboto, sans-serif' }}> 20 </h2>
                  </div>
                  <div className="about_iner_content">
                    <h3 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                      year <span>of Experience</span>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 col-lg-6">
              <div className="about_part_img">
                <img src="img/tis13.jpeg" alt="image" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_project section_padding" id="portfolio">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-lg-5 col-sm-10">
              <div className="section_tittle">
                <h2 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>Our Projects</h2>
              </div>
            </div>
            <div className="col-lg-6 col-sm-10">
              <div className="filters portfolio-filter project_menu_item">
                <ul>
                  <li className="active" data-filter="*">
                    All
                  </li>
                  <li data-filter=".buildings">Buildings</li>
                  <li data-filter=".rebuild">Rebuild</li>
                  <li data-filter=".architecture">Architecture</li>
                </ul>
              </div>
            </div>
          </div>
          <div className="filters-content">
            <div className="row justify-content-between portfolio-grid">
              <div className="col-lg-4 col-sm-6 all buildings">
                <div className="single_our_project">
                  <div className="single_offer">
                    <img src="img/1.jpeg" alt="offer_img_1" />
                    <div className="hover_text">
                      <p>Bank Protected</p>
                      <a href="#">
                        <h2 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                          Banking &amp; Finance
                        </h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 all rebuild">
                <div className="single_our_project">
                  <div className="single_offer">
                    <img src="img/2.jpeg" alt="offer_img_1" />
                    <div className="hover_text">
                      <p>Bank Protected</p>
                      <a href="#">
                        <h2 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                          Banking &amp; Finance
                        </h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-sm-6 all architecture">
                <div className="single_our_project">
                  <div className="single_offer">
                    <img src="img/1.jpeg" alt="offer_img_1" />
                    <div className="hover_text">
                      <p>Bank Protected</p>
                      <a href="#">
                        <h2 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                          Banking &amp; Finance
                        </h2>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="member_counter padding_bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-sm-6">
              <div className="single_counter_icon">
                <img src="img/icon/Icon_1.svg" alt="image" />
              </div>
              <div className="single_member_counter">
                <span className="counter"> 60 </span>
                <h4 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                  {' '}
                  <span> Satisfied </span> Client
                </h4>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single_counter_icon">
                <img src="img/icon/Icon_2.svg" alt="image" />
              </div>
              <div className="single_member_counter">
                <span className="counter"> 10 </span>
                <h4 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                  {' '}
                  <span> Worldwide </span> Branches
                </h4>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single_counter_icon">
                <img src="img/icon/Icon_3.svg" alt="image" />
              </div>
              <div className="single_member_counter">
                <span className="counter"> 80 </span>
                <h4 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                  {' '}
                  <span> Total </span> Projects
                </h4>
              </div>
            </div>
            <div className="col-lg-3 col-sm-6">
              <div className="single_counter_icon">
                <img src="img/icon/Icon_4.svg" alt="image" />
              </div>
              <div className="single_member_counter">
                <span className="counter"> 24 </span>
                <h4 style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}>
                  {' '}
                  <span> Work </span> Finished
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="blog_part section_padding">
        <div className="container">
          <div className="row ">
            <div className="col-xl-5">
              <div className="section_tittle ">
                <h2>Recent news</h2>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 col-lg-4 col-xl-4">
              <div className="single-home-blog">
                <div className="card">
                  <img src="img/blog/tis10.jpeg" className="card-img-top" alt="blog" />
                  <div className="card-body">
                    <ul>
                      <li>
                        {' '}
                        <span className="ti-comments" />2 Comments
                      </li>
                      <li>
                        {' '}
                        <span className="ti-heart" />
                        2k Like
                      </li>
                    </ul>
                    <a href="/projects">
                      <h5
                        style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}
                        className="card-title"
                      >
                        Our two firmament called us kind in face midst
                      </h5>
                    </a>
                    <a href="#" className="btn_3">
                      read more
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 col-xl-4">
              <div className="single-home-blog">
                <div className="card">
                  <img src="img/blog/tis4.jpeg" className="card-img-top" alt="blog" />
                  <div className="card-body">
                    <ul>
                      <li>
                        {' '}
                        <span className="ti-comments" />2 Comments
                      </li>
                      <li>
                        {' '}
                        <span className="ti-heart" />
                        2k Like
                      </li>
                    </ul>
                    <a href="/projects">
                      <h5
                        style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}
                        className="card-title"
                      >
                        Our two firmament called us kind in face midst
                      </h5>
                    </a>
                    <a href="#" className="btn_3">
                      read more
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-6 col-lg-4 col-xl-4">
              <div className="single-home-blog">
                <div className="card">
                  <img src="img/blog/tis8.jpeg" className="card-img-top" alt="blog" />
                  <div className="card-body">
                    <ul>
                      <li>
                        {' '}
                        <span className="ti-comments" />2 Comments
                      </li>
                      <li>
                        {' '}
                        <span className="ti-heart" />
                        2k Like
                      </li>
                    </ul>
                    <a href="/projects">
                      <h5
                        style={{ color: '#001b5e', fontFamily: 'Roboto, sans-serif' }}
                        className="card-title"
                      >
                        Our two firmament called us kind in face midst
                      </h5>
                    </a>
                    <a href="#" className="btn_3">
                      read more
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  )
}

export default Mainpage
