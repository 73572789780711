import React from 'react'

function Footer() {
  return (
    <div>
      <footer className="footer-area">
        <div className="container">
          <div className="row justify-content-between">
            <div className="col-sm-6 col-md-4 col-xl-3">
              <div className="single-footer-widget footer_1">
                <a href="/">
                  <img src="img/v5_tis.jpg" alt="V5 Infra TIS Logo" />
                </a>
                <p style={{ color: '#F0FFF0', textAlign: 'justify' }}>
                  At V5 Infra and Allied Services, we are proud of our reputation for reliability,
                  innovation, and integrity. Whether you&apos;re planning a new banking facility,
                  enhancing your retail space, or transforming your office environment, we are here
                  to partner with you every step of the way. Contact us today to explore how we can
                  turn your vision into reality.
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-md-4">
              <div className="single-footer-widget footer_2">
                <h4>Best Services</h4>
                <div className="contact_info">
                  <ul>
                    <li>
                      <a href="/" style={{ color: 'whitesmoke' }}>
                        Home
                      </a>
                    </li>
                    <li>
                      <a href="/profile" style={{ color: '#F0FFF0' }}>
                        Profile
                      </a>
                    </li>
                    <li>
                      <a href="/services" style={{ color: '#F0FFF0' }}>
                        Service
                      </a>
                    </li>
                    <li>
                      <a href="/projects" style={{ color: '#F0FFF0' }}>
                        Projects
                      </a>
                    </li>
                    <li>
                      <a href="#" style={{ color: '#F0FFF0' }}>
                        Gallery
                      </a>
                    </li>
                    <li>
                      <a href="/contact" style={{ color: '#F0FFF0' }}>
                        Contact
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-md-4">
              <div className="single-footer-widget footer_2">
                <h4>Our Gallery</h4>
                <div className="footer_img">
                  <a href="#">
                    <img src="img/footer_img/col5.jpeg" alt="Gallery Image 1" />
                  </a>
                  <a href="#">
                    <img src="img/footer_img/col6.jpeg" alt="Gallery Image 2" />
                  </a>
                  <a href="#">
                    <img src="img/footer_img/col4.jpeg" alt="Gallery Image 3" />
                  </a>
                  <a href="#">
                    <img src="img/footer_img/col5.jpeg" alt="Gallery Image 4" />
                  </a>
                  <a href="#">
                    <img src="img/footer_img/col6.jpeg" alt="Gallery Image 5" />
                  </a>
                  <a href="#">
                    <img src="img/footer_img/col7.jpeg" alt="Gallery Image 6" />
                  </a>
                  <a href="#">
                    <img src="img/footer_img/col5.jpeg" alt="Gallery Image 7" />
                  </a>
                  <a href="#">
                    <img src="img/footer_img/col6.jpeg" alt="Gallery Image 8" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-sm-6 col-md-4">
              <div className="single-footer-widget footer_2">
                <h4>Contact info</h4>
                <div className="contact_info">
                  <p style={{ color: 'white', textAlign: 'justify' }}>
                    2ND FLOOR, PLOT NO. B-92, FLAT NO. 205
                  </p>
                  <p style={{ textAlign: 'justify' }}>
                    <span> Address :</span>
                    <a style={{ color: 'white' }}>
                      {' '}
                      FIRST AVENUE, AJMER ROAD, GOPAL BARI, Jaipur,Rajasthan, 302001
                    </a>
                  </p>
                  <p>
                    <span> Head Office :</span>
                    <a style={{ color: 'white', textAlign: 'justify' }}>
                      Jay Antariksh Park 803, 8th Floor Marol Makhwana, Andheri (E) Mumbai - 400059
                    </a>
                  </p>
                  <p>
                    <span>Phone::</span>
                    <a id="phoneLink" style={{ color: 'white', textAlign: 'justify' }}>
                      9503824222
                    </a>
                  </p>
                  <p>
                    <span>Email::</span>
                    <a id="emailLink" href="#" style={{ color: 'white', textAlign: 'justify' }}>
                      laxmikant.patnayak@V5logistics.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12">
              <div className="copyright_part_text text-center">
                <div className="row">
                  <div className="col-lg-12">
                    <p className="footer-text m-0" style={{ color: 'white' }}>
                      Copyright © All rights reserved | This template is made with{' '}
                      <i className="ti-heart" aria-hidden="true" /> by{' '}
                      <a href="#" target="_blank">
                        V5 Infra TIS
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
